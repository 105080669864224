import { Button, SelectField } from '@livechat/design-system';
import React, { useEffect, useMemo, useState } from 'react';
import { SimpleCard } from './card/SimpleCard';
import axios from 'axios';
import { useAsync } from 'react-async';

export const GroupSelectionCard = ({ notificationSystem }) => {
  const [selectedItemKey, setSelectedItemKey] = useState(null);

  const { value: organization, run: getOrganization, isLoading: getOrganizationLoading } = useAsync({
    deferFn: () => axios.get('/api/organization').then(response => response.data),
  });

  const { value: groupsResponse, run: getGroups, isLoading: getGroupsLoading } = useAsync({
    deferFn: () => axios.post('/api/livechat/list-groups').then(response => response.data),
  });

  const { run: setGroup, isLoading: setGroupLoading } = useAsync({
    deferFn: () => {
      const item = items.find(i => i.key === selectedItemKey);
      return axios
        .post('/api/livechat/update-route-group', { routeToGroupId: item?.props?.value ?? null })
        .then(response => response.data);
    },
    onResolve: () => {
      notificationSystem.add?.({
        type: 'toast',
        autoHideDelayTime: 5000,
        payload: {
          variant: 'success',
          content: 'Group was saved succesfully!',
          horizontalPosition: 'center',
          verticalPosition: 'top',
          removable: false,
        },
      });
    },
  });

  useEffect(() => {
    getGroups();
    getOrganization();
  }, []);

  const items = useMemo(() => {
    return groupsResponse?.groups.map(g => ({ key: g.id.toString(), props: { name: g.name, value: g.id } }));
  }, [groupsResponse]);

  useEffect(() => {
    if (!getOrganizationLoading && !getGroupsLoading && organization && items) {
      const key = items.find(i => i.props.value === organization?.livechat?.routeToGroupId)?.key ?? null;
      setSelectedItemKey(key);
    }
  }, [getGroupsLoading, getOrganizationLoading, organization, items]);

  const handleSaveGroupClick = async () => {
    setGroup();
  };

  const handleItemSelect = item => {
    if (!item) {
      setSelectedItemKey(null);
    } else {
      setSelectedItemKey(item);
    }
  };

  const getItemBody = props => {
    if (!props) {
      return null;
    }
    return <div id={props.value}>{props.name}</div>;
  };

  const getSelectedItemBody = props => {
    return <div id={props.value}>{props.name}</div>;
  };

  if (getGroupsLoading || getOrganizationLoading) {
    return <SimpleCard title="Group Selection" loading info={<></>}></SimpleCard>;
  }

  const info = (
    <>
      <SelectField
        id="group"
        items={items}
        searchProperty="name"
        onItemSelect={handleItemSelect}
        getItemBody={getItemBody}
        description='If left empty, defaults to "General"'
        labelText="Select group"
        search
        placeholder="Select group"
        getSelectedItemBody={getSelectedItemBody}
        selected={selectedItemKey}
        searchPlaceholder="Search by name..."
        selectHeader="Select a group to route messages to"
      />
    </>
  );

  const footer = (
    <Button loading={setGroupLoading} kind="primary" onClick={() => handleSaveGroupClick()}>
      Save
    </Button>
  );

  return <SimpleCard title="Group Selection" info={info} footer={footer}></SimpleCard>;
};
