import { Button, Loader, notificationConnect, NotificationProvider, ToastConsumer } from '@livechat/design-system';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useAsync } from 'react-async';
import styled from 'styled-components';
import { StringParam, useQueryParam } from 'use-query-params';
import { SimpleCard } from './components/card/SimpleCard';
import { SupportCard } from './components/SupportCard';
import { ErrorCard } from './components/ErrorCard';
import twitterImage from './images/twitter.png';
import { GroupSelectionCard } from './components/GroupSelectionCard';

const Container = styled.div`
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
`;

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #ffffff;
  z-index: 2;
`;

const FirstRow = styled.div`
  display: flex;
`;

export const App = () => {
  const {
    value: organization,
    run: getOrganization,
    isRejected: getOrganizationOperationFailed,
    isSettled: getOrganizationOperationEnded,
  } = useAsync({
    deferFn: () => axios.get('/api/organization').then(response => response.data),
  });

  const {
    run: deleteOrganization,
    isLoading: deleteOrganizationOperationLoading,
    isSettled: deleteOrganizationOperationEnded,
  } = useAsync({
    deferFn: () => axios.delete('/api/organization').then(response => response.data),
  });

  const [status, setStatus] = useQueryParam('status', StringParam);

  const [twitterInProgress, setTwitterInProgress] = useState(false);

  useEffect(() => {
    if (organization) return;
    switch (status) {
      case 'success':
        return getOrganization();
      case 'failure':
        return {};
      case undefined:
        return window.location.replace('/api/livechat');
      default: {
      }
    }
  }, [status]);

  useEffect(() => {
    if (organization) {
      setStatus(undefined);
    }
  }, [organization]);

  useEffect(() => {
    if (deleteOrganizationOperationEnded) {
      getOrganization();
    }
  }, [deleteOrganizationOperationEnded]);

  const handleSignInWithTwitterClick = () => {
    setTwitterInProgress(true);
    const twitterWindow = window.open('/api/twitter');
    let count = 0;
    const interval = setInterval(() => {
      count = count + 1;
      if (count > 6000) {
        clearInterval(interval);
        setTwitterInProgress(false);
      }
      if (twitterWindow.closed) {
        clearInterval(interval);
        setTwitterInProgress(false);
        getOrganization();
      }
    }, 50);
    twitterWindow.focus();
  };

  const handleDisconnectClick = async () => {
    deleteOrganization();
    setTwitterInProgress(false);
  };

  if (getOrganizationOperationFailed) {
    return <ErrorCard error="Cannot get organization" />;
  }

  const ToastedGroupSelectionCard = notificationConnect(GroupSelectionCard);

  return (
    <NotificationProvider itemsLimit={2} queueLimit={1}>
      <ToastConsumer horizontalPosition="center" fixed verticalPosition="top" />

      <Container>
        {!getOrganizationOperationEnded && !organization && (
          <LoaderContainer>
            <Loader size="large" />
          </LoaderContainer>
        )}

        <FirstRow>
          {!!organization && !organization?.twitter && (
            <SimpleCard
              title="Connect Account"
              info="Please use the following button to connect your Twitter and Livechat accounts"
              image={twitterImage}
              footer={
                <Button
                  loading={twitterInProgress || !getOrganizationOperationEnded}
                  onClick={handleSignInWithTwitterClick}
                  kind="primary"
                  loaderLabel="In Progress"
                >
                  Sign in with Twitter
                </Button>
              }
            />
          )}
          {!!organization && !!organization?.twitter && (
            <SimpleCard
              title="Connected Account"
              info={
                <>
                  Your Livechat account is connected your Twitter with username{' '}
                  <strong>@{organization.twitter.screenName}</strong>
                </>
              }
              image={twitterImage}
              footer={
                <Button
                  loading={!getOrganizationOperationEnded || deleteOrganizationOperationLoading}
                  kind="destructive"
                  onClick={() => handleDisconnectClick()}
                >
                  Disconnect
                </Button>
              }
            />
          )}
          <ToastedGroupSelectionCard />
        </FirstRow>

        <FirstRow>
          <SupportCard />
          <SimpleCard
            title="Limitations"
            info={
              <>
                Emoji reactions to messages are not supported for external Twitter applications. However, sending and
                receiving emojis in messages are supported.
              </>
            }
          />
        </FirstRow>
      </Container>
    </NotificationProvider>
  );
};
