import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { createBrowserHistory } from 'history';
import React, { useEffect, useReducer } from 'react';
import ReactDOM from 'react-dom';
import { QueryParamProvider } from 'use-query-params';
import { App } from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';

Sentry.init({
  dsn: 'https://85f9b588fcbd498e92d7755f9673ae1b@o490594.ingest.sentry.io/5563970',
  environment: process.env.REACT_APP_ENV || process.env.NODE_ENV || 'production',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

const history = createBrowserHistory();

const Root = ({ children }) => {
  const [, forceUpdate] = useReducer(x => x, 0);

  useEffect(() => {
    history.listen(() => {
      forceUpdate();
    });
  }, []);

  return children;
};

ReactDOM.render(
  <React.StrictMode>
    <QueryParamProvider history={history}>
      <Root>
        <App />
      </Root>
    </QueryParamProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
